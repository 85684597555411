'use client'

import { useRef, useEffect, useState, useMemo } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import useSWR from 'swr'

import { useConfigsContext } from '@/context/ConfigsContext'
import { useAuthContext } from '@/context/AuthContext'
import { useVSCSdkContext } from '@/context/VSCSdkContext'
import { HubsStoreState } from '@/types/store'
import { cn } from '@/lib/utils'
import ViverseCommunityDialog from '@/components/ViverseCommunityDialog'
import { getPointBalance } from '@/services/community'

const createProfile = (profile: HubsStoreState['profile'], points?: number) => ({
  displayName: profile?.displayName,
  activeAvatar: {
    id: profile?.avatarId,
    snapshot_url: profile?.avatarSnapshot,
    avatar_url: profile?.avatarGlb,
    head_icon_url: profile?.avatarSnapshot,
    gender: profile?.avatarGenderKey,
    data_type: profile?.avatarDataType,
    is_half_body: false,
  },
  userID: profile?.userId,
  points,
})

function Header() {
  const headerRef = useRef<HTMLDivElement>(null)
  const [openCommunityDialog, setOpenCommunityDialog] = useState(false)

  const router = useRouter()
  const pathname = usePathname()
  const { profile, communityToggle, credentials } = useConfigsContext()
  const { isSignedIn, handleLogin, handleLogout } = useAuthContext()
  const { viverseShareComponent, isMountedHeader, setIsMountedHeader, changeLocale } =
    useVSCSdkContext()

  const htcToken = credentials?.htcToken
  const { data } = useSWR(!htcToken ? null : 'pointBalance', () =>
    getPointBalance(htcToken!).then((res) => res?.results?.data),
  )
  const pointBalance = data?.pointBalance || 0

  const shareComponentProfile = useMemo(() => {
    return isSignedIn ? createProfile(profile!, pointBalance) : {}
  }, [isSignedIn, profile, pointBalance])

  useEffect(() => {
    function handleCreateWorld() {
      router.push('/profile?create')
    }

    // 🌎 handle CustomEvent from share-component
    document.addEventListener('createWorld', handleCreateWorld)

    return () => {
      document.removeEventListener('createWorld', handleCreateWorld)
    }
  }, [router])

  useEffect(() => {
    if (isMountedHeader && viverseShareComponent) {
      viverseShareComponent.updateProfile(shareComponentProfile)
    }
  }, [isMountedHeader, viverseShareComponent, isSignedIn, shareComponentProfile])

  useEffect(() => {
    if (viverseShareComponent && headerRef.current) {
      viverseShareComponent.mountHeader({
        $header: headerRef.current,
        localeConfig: {
          changeLocale,
        },
        authConfig: {
          onLogin: handleLogin,
          onLogout: handleLogout,
          changeRoute: (origin: string, path: string) => {
            window.location.href = `${origin}${path}`
            // if (window.location.origin === origin) {
            //   router.push(path);
            // } else {
            //   router.push(`${origin}${path}`)
            // }
          },
          profile: shareComponentProfile,
        },
        layoutConfig: {
          type: 4,
          community: {
            isChinaUser: !communityToggle,
            showIframe: () => setOpenCommunityDialog(true),
          },
        },
      })

      setIsMountedHeader(true)
    }
  }, [
    handleLogin,
    handleLogout,
    setIsMountedHeader,
    shareComponentProfile,
    viverseShareComponent,
    communityToggle,
    changeLocale,
  ])

  return (
    <>
      <header
        ref={headerRef}
        className={cn('h-[56px] w-full transition-all lg:h-[80px]', {
          'fixed top-0 z-50': pathname !== '/profile',
          static: pathname === '/profile',
          'bg-[#12121699]': viverseShareComponent === null,
          'bg-transparent': viverseShareComponent !== null,
        })}
      />
      <ViverseCommunityDialog open={openCommunityDialog} onOpenChange={setOpenCommunityDialog} />
    </>
  )
}

export default Header
