import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BISdkProvider"] */ "/app/context/BISdkContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigsProvider"] */ "/app/context/ConfigsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VSCSdkProvider"] */ "/app/context/VSCSdkContext.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@_nbstnichny2xkfbwoohgdywvn4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@_nbstnichny2xkfbwoohgdywvn4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@_nbstnichny2xkfbwoohgdywvn4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_6rbemi52tdgxfkgds7yq3rd5bm/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3_hv6ediejngkoiwdsbzekpqwbxe/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3_hv6ediejngkoiwdsbzekpqwbxe/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
